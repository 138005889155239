import { bopsApi } from '@Network';
import { AppDispatch } from '../store';
import { actions } from './users.reducer';

const fetchUsers = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.fetchUserRequest());
    try {
      const users = await bopsApi.getUsers('', 0, 9, null, null);
      dispatch(actions.fetchUserSuccess({ users: users?.users, total: users?.total }));
    } catch (error: any) {
      dispatch(
        actions.fetchUserFailure({
          error: error?.response?.data?.detail || error?.message || 'Unknown error fetching users list',
        }),
      );
    }
  };
};

/* eslint-disable import/prefer-default-export */
export { fetchUsers };
