import { Title } from 'chart.js';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

/* eslint-disable @typescript-eslint/no-floating-promises */
i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: {
      en: {
        translation: {
          Sidebar: {
            Main: 'Main',
            Monitor: 'Monitor',
            SecondaryMonitor: 'Admin Monitor',
            Overstock: 'Overstock',
            Configure: 'Configure',
            Logout: 'Logout',
            Toolkit: 'Toolkit',
            Documentation: 'Documentation',
            Insights: 'Insights',
            TrainingVideos: 'Training Videos',
            Certification: 'Certification',
            ResetDashboard: 'Reset to original dashboard',
            Credentials: 'Credentials',
            Tools: 'Tools',
            Gpc: 'GPC Browser',
            Syncfonia: 'Syncfonia+',
            Verified: 'Verified',
            Users: 'Users',
            Suppliers: 'Suppliers',
            Collapse: 'Collapse',
            ManualUpload: 'Manual Upload',
          },
          Global: {
            ErrorMessage:
              "Something has gone wrong, but it's likely to work again soon.\nCheck back in a while, and if it's still not working contact us at support@bops.ai.",
            WaitingMessage: 'Please wait...\nbops is getting your data',
            UnathorizedMessage:
              "You're not authorized to view this page.\nIf you think this is an error please contact us at support@bops.ai.",
            PageNotFoundMessage: "Page not found.\nWe can't seem to find the page you're looking for.",
            NoAccountMessage:
              'It seems like your access is not yet configured!\n\nPlease contact your account administrator to complete the setup\nand gain access to your inventory recommendations.',
            GoHome: 'Go to Monitor',
            Confirm: 'Confirm',
            Canceled: 'Canceled',
            Select: 'Select',
            Required: 'is required',
            Page: 'Page',
            Of: 'of',
            Next: 'Next',
            Previous: 'Previous',
          },
          Recommendation: {
            Loading: "Hang on we're gathering your recommendations...",
            ImportantOverstock: 'Important Overstock',
            Overstock: 'Overstock',
            PotentialOverstock: 'Potential Overstock',
            PotentialUnderstock: 'Potential Understock',
            Understock: 'Understock',
            PotentialStockout: 'Potential Stockout',
            StoreStockout: 'Store Stockout',
            CompleteStockout: 'Complete Stockout',
            InventoryEfficiencyScore: 'Inventory Efficiency Score',
            InventoryDays: 'Inventory Days',
            Dashboard: 'Dashboard',
            WelcomeMessageHi: 'Hi',
            WelcomeMessageHere: 'Here',
            WelcomeMessageFinal:
              ' is a summary of your current score and {{length}} recommendations that can help simplify your inventory decision-making process.',
            TopRecommendations: 'Top {{length}} Recommendations',
          },
          Monitor: {
            Loading: "Hang on we're gathering your dashboard...",
          },
          Configuration: {
            Loading: "Hang on we're gathering your configuration files...",
            Title: 'Inventory Policy Configuration',
            Message:
              'These files are used to drive the configuration of your inventory recommended actions.\nConfiguration updates will be applied by the end of the business day.',
            Open: 'Open',
            Uploading: 'Uploading',
            Replace: 'Replace',
            Error: 'There has been an error updating the file, please try again.',
            Success: 'File uploaded successfully!',
            All: 'All configurations',
            Name: 'Name',
            Status: 'Status',
            Active: 'Active',
          },
          Credentials: {
            Loading: "Hang on we're gathering your credentials...",
            Title: 'Credentials',
            Subtitle: 'Update your data provider credentials here',
            Username: 'Username',
            Password: 'Password',
            Token: 'Token',
            Account: 'Account',
            Update: 'Update',
            Save: 'Save',
            Cancel: 'Cancel',
            TitleModal: 'Update Credentials',
            Name: 'Name',
            Added: 'Added on',
            LastUpdated: 'Last updated',
            AllCredentials: 'All Credentials',
            Activate: 'Activate',
            Pending: 'Pending',
            LoadingModal: 'Checking if  the {{name}} configuration is okay',
            UpdateSuccess: 'The credentials have been successfully updated',
            Status: 'Status',
            Error: "There's been an error updating the credentials, please try again.",
            RequestCredentials:
              "It seems like we don't have your inventory data!\n\nPlease update your credentials to complete the setup\nand gain access to your inventory recommendations.",
            RequestCredentialsButton: 'Set Credentials',
            HasCredentialsButton: 'Documentation',
            Success: 'Credentials have been successfully updated',
            Active: 'Active',
            Paused: 'Paused',
            Expired: 'Expired',
            HasCredentials:
              'Thank you for joining Inventory Intelligence!\n\nWe are processing your product information for analysis. If you have any questions, please contact the support team present in the documentation.',
          },
          ManualUpload: {
            Title: 'Manual Upload',
            Uploading: 'Uploading',
            Loading: "Hang on we're gathering the files you can upload...",
            Upload: 'Upload',
            LoadingProgress: 'Uploading files',
            All: 'All files that can be uploaded',
            Success: 'have been uploaded successfully!',
            Error: 'There has been an error uploading the file, please try again.',
          },
          User: {
            Title: 'Users',
            ErrorEmail: 'Introduce a valid email',
            UsernameNotEmail: 'The username cannot be the same as the email',
            ErrorUsername:
              'Username can only contain alphanumeric characters and the following characters: "_" ,"+", "-", ".","!","#", "$", "^","~", "@", ","',
            BlockLabel: 'Block',
            UnBlockLabel: 'Unblock',
            ErrorCreate: 'Error creating user',
            ErrorAlreadyExists: 'Error creating user. User already exists',
            ErrorBlock: 'Error blocking user',
            Loading: "Hang on we're gathering your users...",
            ConfirmBlock: 'Are you sure you want to block the user\n',
            ConfirmUnBlock: 'Are you sure you want to unblock the user\n',
            Select: 'Select a client',
            Add: 'Add new user',
            Never: 'Never',
            All: 'All Users',
            TitleModal: 'New User',
            AddSuccess: 'has been added succesfully!',
            LoadingCreate: 'User is creating',
            Client: 'Client',
            Status: 'Status',
            Email: 'Email',
            Username: 'Username',
            LastLogin: 'Last Login',
            Search: 'Search by email',
            Blocked: 'Blocked',
            Active: 'Active',
            UsernameLengthError: 'Username must be between 6 and 30 characters',
          },
          Supplier: {
            Loading: "Hang on we're gathering your suppliers...",
            Add: 'Add supplier',
            Title: 'Suppliers Management',
            Progress: 'In Progress',
            WithData: 'Data available',
            WithoutData: 'Missing Data',
            WithDataTooltip: 'Data for this supplier is available',
            WithoutDataTooltip:
              'Data for this supplier is not available. Please request credentials for Walmart/Soriana.',
            ProgressTooltip: 'Creation is in progress. Supplier data will be available within 24 hours.',
            Exist: 'Supplier already exits',
            AddMessage: 'has been successfully added',
            Status: 'Status',
            Name: 'Name',
            Connectors: 'Credentials',
            Error: 'Error creating the supplier',
            LoadingCreate: 'Creating supplier',
            All: 'All Suppliers',
            TitleModal: 'New Supplier',
            Search: 'Search for GLN or name',
            Pending: 'Pending for refresh data',
          },
        },
      },
      es: {
        translation: {
          Sidebar: {
            Main: 'Recomendaciones',
            Monitor: 'Portal',
            SecondaryMonitor: 'Administrativo',
            Overstock: 'Exceso Inventario',
            Configure: 'Configuración',
            Logout: 'Salir',
            Toolkit: 'Recursos CPFR',
            Documentation: 'Documentación',
            Insights: 'Eventos',
            TrainingVideos: 'Videos',
            Certification: 'Certificación',
            ResetDashboard: 'Reiniciar portal a su estado original',
            Credentials: 'Credenciales',
            Tools: 'Herramientas',
            Gpc: 'Buscador GPC',
            Syncfonia: 'Syncfonía+',
            Verified: 'Verified',
            Users: 'Usuarios',
            Suppliers: 'Proveedores',
            Collapse: 'Colapsar',
            ManualUpload: 'Carga Manual',
          },
          Global: {
            ErrorMessage:
              'Algo ha salido mal, pero es probable que vuelva a funcionar pronto.\nVuelve a intentarlo más tarde y, si aún no funciona, contáctanos en support@bops.ai',
            WaitingMessage: 'Por favor espera...\nEstamos obteniendo tu data',
            UnathorizedMessage:
              'No tienes permiso para ver esta página.\nSi piensas que esto es un error contáctanos a support@bops.ai.',
            PageNotFoundMessage: 'Página no encontrada.\nLa página que buscas no está disponible.',
            NoAccountMessage:
              '¡Parece que tu acceso aún no se encuentra configurado!\n\nPonte en contacto con el administrador de cuenta para completar la configuración\ny obtener acceso a tus recomendaciones de inventario.',
            GoHome: 'Ir al Portal',
            Confirm: 'Confirmar',
            Canceled: 'Cancelar',
            Select: 'Seleccionar',
            Required: 'es requerido',
            Page: 'Página',
            Of: 'de',
            Next: 'Siguiente',
            Previous: 'Anterior',
          },
          Recommendation: {
            Loading: 'Por favor espera...\nEstamos cargando tus recomendaciones',
            ImportantOverstock: 'Exceso de Inventario Importante',
            Overstock: 'Exceso de Inventario',
            PotentialOverstock: 'Posible Exceso de Inventario',
            PotentialUnderstock: 'Posible Bajo Inventario',
            Understock: 'Bajo Inventario',
            PotentialStockout: 'Posible Inventario Agotado',
            StoreStockout: 'Inventario Agotado en Tienda',
            CompleteStockout: 'Inventario Agotado',
            InventoryEfficiencyScore: 'Score de Eficiencia de Inventario',
            InventoryDays: 'Días de Inventario',
            Dashboard: 'Portal',
            WelcomeMessageHi: 'Hola',
            WelcomeMessageHere: 'Aquí',
            WelcomeMessageFinal:
              ' este es un resumen de tu score actual y {{length}} recomendaciones que ayudarán a simplificar tu toma de decisiones en cuanto a inventario.',
            TopRecommendations: 'Top {{length}} Recomendaciones',
          },
          Monitor: {
            Loading: 'Por favor, espera... Estamos recopilando tu portal',
          },
          Configuration: {
            Loading: 'Por favor, espera... Estamos recopilando tus archivos de configuración',
            Title: 'Configuración de Política de Inventario',
            Message:
              'Estos archivos se utilizan para controlar la configuración de tus recomendaciones de inventario.\nLas actualizaciones de configuración serán aplicadas al final del día hábil.',
            Open: 'Abrir',
            Replace: 'Sustituir',
            Uploading: 'Subiendo',
            Error: 'Ha ocurrido un error al actualizar el archivo, por favor intenta de nuevo.',
            Success: 'Archivo subido correctamente!',
            All: 'Todas las configuraciones',
            Name: 'Nombre',
            Status: 'Estado',
            Active: 'Activo',
          },
          Credentials: {
            Loading: 'Por favor, espera... Estamos recopilando tus credenciales',
            Title: 'Credenciales',
            Subtitle: 'Actualiza las credenciales de tus proveedores de datos aquí',
            Username: 'Username',
            Password: 'Password',
            Token: 'Token',
            Update: 'Editar',
            Account: 'Cuenta',
            Save: 'Actualizar',
            Cancel: 'Cancelar',
            Activate: 'Activar',
            TitleModal: 'Edita las credenciales',
            Name: 'Nombre',
            Added: 'Agregado',
            LastUpdated: 'Ultima actualización',
            Active: 'Activo',
            Paused: 'Pausado',
            Expired: 'Expirado',
            Pending: 'Pendiente',
            AllCredentials: 'Todas las Credenciales',
            Status: 'Estado',
            UpdateSuccess: 'Las credenciales se han actualizado correctamente',
            LoadingModal: 'Chequeando si la configuracion de {{name}} esta bien',
            Error: 'Ha ocurrido un error al actualizar las credenciales, por favor intenta de nuevo.',
            RequestCredentials:
              '¡No tenemos información de tus productos!\n\nActualiza tus credenciales de Walmart y/o Soriana para que obtengas\nacceso a tu Inteligencia de Inventario.',
            RequestCredentialsButton: 'Configurar credenciales',
            HasCredentialsButton: 'Documentación',
            Success: 'Las credenciales se han actualizadas correctamente',
            HasCredentials:
              '¡Gracias por ingresar a Inventario Inteligente!\n\nEstamos procesando la información de tus productos para su análisis.\nEn caso de tener alguna duda, por favor contactar al equipo de soporte presente en la documentación.',
          },
          ManualUpload: {
            Title: 'Carga manual',
            Uploading: 'Subiendo',
            Upload: 'Subir',
            All: 'Todas los archivos que puedes cargar',
            Loading: 'Por favor espera...\nEstamos recopilando los archivos que puedes cargar',
            Success: 'han sido subido(s) correctamente!',
            LoadingProgress: 'Cargando los archivos',
            Error: 'Ha ocurrido un error al subir el archivo, por favor intenta de nuevo.',
          },
          User: {
            Title: 'Usuarios',
            ErrorEmail: 'Introduce un correo válido',
            UsernameNotEmail: 'El usuario no puede ser igual al correo',
            ErrorUsername:
              'El usuario sólo puede contener los siguientes caracteres alfanuméricos : "_" ,"+", "-", ".","!","#", "$", "^","~", "@", ","',
            BlockLabel: 'Bloquear',
            UnBlockLabel: 'Desbloquear',
            ErrorCreate: 'Error creando al usuario',
            ErrorAlreadyExists: 'Error creando al usuario. El usuario ya existe.',
            ErrorBlock: 'Error bloqueando al user',
            Loading: 'Por favor espera...\nEstamos cargando tus usuarios',
            ConfirmBlock: '¿Estás seguro que quieres bloquear al usuario\n',
            ConfirmUnBlock: '¿Estás seguro que quieres desbloquear al usuario\n',
            Select: 'Seleccionar un cliente',
            Add: 'Añadir nuevo Usuario',
            Never: 'Nunca',
            All: 'Todos los Usuarios',
            TitleModal: 'Nuevo Usuario',
            AddSuccess: 'se ha añadido correctamente',
            LoadingCreate: 'El usuario se esta creando',
            Client: 'Cliente',
            Status: 'Estado',
            Email: 'Correo',
            Username: 'Usuario',
            LastLogin: 'Último ingreso',
            Search: 'Buscar por correo',
            Blocked: 'Bloqueado',
            Active: 'Activo',
            UsernameLengthError: 'El usuario debe tener entre 6 y 30 caracteres',
          },
          Supplier: {
            Loading: 'Por favor espera...\nEstamos cargando tus proveedores',
            Add: 'Añadir nuevo proveedor',
            Title: 'Administración de Proveedores',
            Exist: 'Proveedor ya existe',
            AddMessage: 'se ha añadido exitosamente',
            Progress: 'En Progreso',
            WithData: 'Data Disponible',
            WithoutData: 'Data Faltante',
            WithDataTooltip: 'Los datos de este proveedor están disponibles',
            WithoutDataTooltip:
              'Los datos de este proveedor no están disponibles. Por favor, solicitar credenciales para Walmart/Soriana',
            ProgressTooltip: ' La creación está en progreso. Los datos del proveedor estarán disponibles en 24 horas.',
            Status: 'Estado',
            Name: 'Nombre',
            Connectors: 'Credenciales',
            Error: 'Error creando el proveedor',
            LoadingCreate: 'Creando el proveedor',
            All: 'Todos los Proveedores',
            TitleModal: 'Nuevo Proveedor',
            Search: 'Buscar por GLN o nombre',
            Pending: 'Pendiente por refrescar data',
          },
        },
      },
    },
  });

export default i18n;
