import { useState } from 'react';
import mime from 'mime';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from '@Helpers';

import { sendCustomEvent, sendErrorEvent } from '@Analytics';
import { Column, ModalLoading, ModalSuccess, Table } from '@Components';
import { bopsApi } from '@Network';
import { InputFile } from '../FileLinkCard/fileLinkCard.styles';

interface ManualUpload {
  name: string;
  date: string;
}

interface DateInfo {
  label: string;
  value: string;
}

interface ValueType {
  name: string;
}

const ManualUploadList = ({
  configures,
  setLoading,
  retailer,
  refetch,
}: {
  configures: ManualUpload[];
  setLoading: (loading: boolean) => void;
  retailer: string;
  refetch: (retailer: string) => void;
}) => {
  const { t } = useTranslation();
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [filesName, setFilesNames] = useState('');
  const [successModal, setSuccessModal] = useState(false);

  const uploadToAWS = async (uploadUrl: string, file, contentType, clientName: string) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'content-type': contentType },
      body: file,
    };

    const response = await fetch(uploadUrl, requestOptions);
    if (response.status !== 200) {
      const textResponse = await response.text();
      sendErrorEvent(`unable to upload file to AWS S3. ${textResponse}`);
      throw Error(`invalid response ${response}`);
    }

    sendCustomEvent('MANUAL_FILE', { type: 'UPLOADED', clientName, fileName: file.name });
    await bopsApi.reportFileUploaded(clientName, file.name, contentType, retailer);
  };

  const uploadFile = (input: any, clientName: string): void => {
    setLoadingFiles(true);
    const uploadAsync = async () => {
      const { files } = input.target;
      const filesList = Array.from(files);
      const fileNames = filesList.map((file: any) => file.name).join(', ');
      setFilesNames(fileNames);

      await Promise.all(
        filesList.map(async (file: any) => {
          const fileName = file.name;
          const contentType = mime.getType(fileName) || 'text/csv';
          const link = await bopsApi.buildUploadLink(clientName, fileName, contentType, retailer);

          sendCustomEvent('MANUAL_FILE', { type: 'UPLOADING', clientName, fileName });

          return uploadToAWS(link, file, contentType, clientName).catch((e) => {
            setLoadingFiles(false);
            sendErrorEvent(`unable to manual upload file to AWS S3. ${e}`);
            throw new Error(`Invalid response`);
          });
        }),
      );
    };

    uploadAsync()
      .then(() => {
        setLoadingFiles(false);
        setSuccessModal(true);
        refetch(retailer);
      })
      .catch((e) => {
        sendErrorEvent(`unable to request manual upload file. ${e}`);
        setLoadingFiles(false);
        showErrorToast(t('ManualUpload.Error'));
      });
  };

  const builderCell = (
    type: string,
    accessor: string,
    value: string | { name: string } | DateInfo[],
    icon: string,
  ): JSX.Element => {
    if (type === 'icon')
      return (
        <div className="flex  items-center">
          <img src={icon} alt="icon" className="w-[25px] mr-2" />
          <span>{value}</span>
        </div>
      );
    if (type === 'label') {
      return <span className="font-semibold">{value}</span>;
    }
    if (type === 'date') {
      const formattedValue = Array.isArray(value) ? (
        value.map((part) => (
          <span key={part.label + retailer}>
            <span className="font-semibold">{`${part.label}: `}</span>
            <span className="mr-2">{part.value}</span>
          </span>
        ))
      ) : (
        <span>{value}</span>
      );
      return <span>{formattedValue}</span>;
    }
    if (type === 'button') {
      if (value && typeof value !== 'object') return <div />;
      const clientName = typeof value === 'object' ? (value as ValueType)?.name : '';
      return (
        <div className="flex gap-2">
          <label
            htmlFor={clientName}
            className="flex items-center justify-center px-3 rounded-md m-auto mr-2 text-sm font-semibold h-10 w-auto border-solid bg-primary shadow-md text-white hover:border-bg-primaryDark hover:bg-primaryDark cursor-pointer hover:shadow-sm">
            {t('ManualUpload.Upload')}
            <InputFile
              id={clientName}
              type="file"
              className="hidden"
              multiple
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => uploadFile(e, clientName)}
            />
          </label>
        </div>
      );
    }
    return <div key={accessor}>{value}</div>;
  };

  const getValueCell = (
    row: {
      original: {
        [key: string]: any;
      };
    },
    key: string,
    type: string,
  ): string | { name: string } => {
    const cellValue = row?.original?.[key];
    if (type === 'button') return row?.original as { name: string };
    return cellValue as string | { name: string };
  };

  const getColumns = (): Column[] => {
    const columns = [
      {
        label: t('Configuration.Name'),
        accessor: 'name',
        key: 'name',
        type: 'label',
        width: 'w-[20%]',
      },
      {
        label: t('Credentials.LastUpdated'),
        accessor: 'date',
        key: 'date',
        type: 'date',
        width: 'w-[70%]',
      },
      {
        label: '',
        type: 'button',
        accessor: 'action',
        key: 'status',
        width: 'w-[20%]',
      },
    ];

    const headers = columns.map((column) => {
      return {
        Header: column.label,
        accessor: column.accessor,
        Cell: ({ row }) => {
          const cellValue = getValueCell(row, column.key, column.type);
          return builderCell(column.type, column.accessor, cellValue, row?.original?.icon);
        },
        width: column.width,
      };
    });
    return headers;
  };

  return (
    <div className="flex mt-[10px]">
      <Table columns={getColumns()} data={configures} pageSize={9} pagination />
      <ModalLoading isOpen={loadingFiles} onClose={() => setLoadingFiles(false)}>
        <div className="text-center -mt-[9px]">
          {`${t('ManualUpload.LoadingProgress')} `} <span className="text-center"> {filesName}</span>
        </div>
      </ModalLoading>
      <ModalSuccess isOpen={successModal} onClose={() => setSuccessModal(false)}>
        <div className="text-center">
          <span className="text-[18px]">
            {filesName} {t('ManualUpload.Success')}
          </span>
        </div>
      </ModalSuccess>
    </div>
  );
};

export default ManualUploadList;
