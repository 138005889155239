import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CredentialCardProps, CredentialsList, Input, PageWrapper } from '@Components';

interface CredentialsProps {
  credentialUpdated: () => void;
  credentials: CredentialCardProps[];
}

const Credentials = ({ credentials, credentialUpdated }: CredentialsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('aquii');
  };

  return (
    <div className={`${loading ? 'pointer-events-none ' : ' '} bg-white w-full  h-full`}>
      <PageWrapper>
        <div className="flex">
          <div className="text-3xl font-semibold mb-6  text-black-100">{t('Credentials.Title')}</div>
        </div>
        <div className="border border-gray-100 border-solid rounded-xl">
          <div className="flex md:flex-row mx-6 my-4 items-center flex-col">
            <span className="font-bold text-xl justify-start text-black-100">
              {`${t('Credentials.AllCredentials')} (${credentials?.length})`}
            </span>
          </div>

          <CredentialsList credentials={credentials} credentialUpdated={credentialUpdated} setloading={setLoading} />
        </div>
      </PageWrapper>
    </div>
  );
};
export default Credentials;
