import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useSortBy, useTable } from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { Column, Table } from '@Components';
import { DataSupplier } from '@Models';
import { ACCOUNT_PENDING_REFRESH, ACCOUNT_READY, ACCOUNT_WITHOUT_DATA, CREATION_IN_PROGRESS } from './const';

const SupplierList = ({ suppliers }: { suppliers: DataSupplier[] }) => {
  const { t } = useTranslation();

  const getStatus = (status: string): { label: string; tooltip: string } => {
    switch (status) {
      case CREATION_IN_PROGRESS:
        return { label: t('Supplier.Progress'), tooltip: t('Supplier.ProgressTooltip') };
      case ACCOUNT_READY:
        return { label: t('Supplier.WithData'), tooltip: t('Supplier.WithDataTooltip') };
      case ACCOUNT_WITHOUT_DATA:
        return { label: t('Supplier.WithoutData'), tooltip: t('Supplier.WithoutDataTooltip') };
      case ACCOUNT_PENDING_REFRESH:
        return { label: t('Supplier.Pending'), tooltip: t('Supplier.Pending') };
      default:
        return { label: '', tooltip: '' };
    }
  };

  const levelOrder = {
    [CREATION_IN_PROGRESS]: 1,
    [ACCOUNT_PENDING_REFRESH]: 2,
    [ACCOUNT_READY]: 3,
    [ACCOUNT_WITHOUT_DATA]: 4,
  };

  const supplierSorted = suppliers.sort((a, b) =>
    `${levelOrder[a?.configuration?.status]}`.localeCompare(`${levelOrder[b?.configuration?.status]}`),
  );

  const buildTooltip = ({ id, content }: { id: string; content: string }) => {
    return <ReactTooltip id={id} positionStrategy="fixed" place="top" content={content} offset={18} />;
  };

  const buildBadge = (color: string, label: string, toolInfo: string | null = null) => {
    return (
      <>
        <div
          data-tooltip-id={label}
          id={label}
          key={label}
          className={`${color} border-solid rounded-md px-[13px] py-[5px] flex w-fit`}>
          <span className="text-left">{label}</span>
        </div>

        {toolInfo && buildTooltip({ id: label, content: toolInfo })}
      </>
    );
  };

  const buildBadges = (values: string[]): JSX.Element => {
    const badgeMapping = {
      gs1_wrl: { label: 'Walmart', color: 'bg-blue-100 text-blue-800 border-blue-800' },
      soriana: { label: 'Soriana', color: 'bg-green-100 text-green-500 border-green-400' },
    };

    return (
      <div className="flex gap-2">
        {values?.map((value: string, index: number) => {
          const { label = value, color = 'bg-gray-100' } = badgeMapping[value] || {};
          return buildBadge(color, label);
        })}
      </div>
    );
  };

  const builderCell = (type: string, accessor: string, value): JSX.Element => {
    if (accessor === 'connectors') {
      if (!value) return <div />;
      return buildBadges(value);
    }
    if (type === 'badge-status') {
      const color =
        value === 'ACCOUNT_READY' ? 'bg-green-100 border-green-500 text-green-500' : 'bg-gray-50 border-gray-100';
      return buildBadge(color, getStatus(value).label, getStatus(value).tooltip);
    }
    return <div key={accessor}>{value}</div>;
  };

  const getValueCell = (row: Row<DataSupplier>, key: string): string => {
    let cellValue = row?.original[key];
    if (key === 'configuration.status') cellValue = row?.original?.configuration?.status;
    if (key === 'configuration.connectors') cellValue = row?.original?.connectors;
    return cellValue;
  };

  const customSort = (rowA, rowB, columnId, desc) => {
    const a = rowA.original[columnId];
    const b = rowB.original[columnId];

    if (a.length > b.length) return desc ? 1 : -1;
    if (b.length > a.length) return desc ? -1 : 1;
    return 0;
  };

  const getColumns = () => {
    const columns = [
      { label: 'GLN', accessor: 'name', key: 'name', type: 'string', width: 'w-[20%]' },
      { label: t('Supplier.Name'), accessor: 'display_name', key: 'display_name', type: 'string', width: 'w-[30%]' },
      {
        label: t('Supplier.Status'),
        accessor: 'status',
        key: 'configuration.status',
        type: 'badge-status',
        width: 'w-[26%]',
      },
      {
        label: t('Supplier.Connectors'),
        accessor: 'connectors',
        key: 'configuration.connectors',
        type: 'string',
        width: 'w-[25%]',
        sortType: customSort,
      },
    ];

    const headers = columns.map((column) => {
      return {
        Header: column.label,
        accessor: column.accessor,
        Cell: ({ row }) => {
          const cellValue = getValueCell(row, column.key);
          return builderCell(column.type, column.accessor, cellValue);
        },
        width: column.width,
        ...(column.sortType && { sortType: column.sortType }),
      };
    });

    return headers;
  };

  return (
    <div className="flex mt-[10px]">
      <Table columns={getColumns()} data={supplierSorted} pageSize={9} pagination />
    </div>
  );
};

export default SupplierList;
