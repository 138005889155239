import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ButtonNew, Column, CredentialCardProps, CredentialsModal, Table } from '@Components';

const CredentialsList = ({
  credentials,
  credentialUpdated,
  setloading,
}: {
  credentials: CredentialCardProps[];
  credentialUpdated: () => void;
  setloading: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();

  const buildBadges = (value: string): JSX.Element => {
    const badgeMapping: { [key: string]: { label: string; color: string; colorDot: string; colorLabel: string } } = {
      ACTIVE: {
        label: t('Credentials.Active'),
        color: 'bg-green-100 border-green-200',
        colorDot: 'bg-green-400',
        colorLabel: 'text-green-500',
      },
      EXPIRED: {
        label: t('Credentials.Expired'),
        color: 'bg-orange-100 border-orange-200',
        colorDot: 'bg-orange-600',
        colorLabel: 'text-orange-800',
      },
      PAUSED: {
        label: t('Credentials.Paused'),
        color: 'bg-orange-100 border-orange-200',
        colorDot: 'bg-orange-600',
        colorLabel: 'text-orange-800',
      },
      PENDING: {
        label: t('Credentials.Pending'),
        color: 'bg-orange-100 border-orange-200',
        colorDot: 'bg-orange-600',
        colorLabel: 'text-orange-800',
      },
    };

    return (
      <div className={`${badgeMapping?.[value]?.color} px-[10px] py-[4px] border-solid rounded-lg flex w-fit`}>
        <div className={`${badgeMapping?.[value]?.colorDot}  h-1.5 w-1.5 flex self-center rounded`} />
        <span className={`${badgeMapping?.[value]?.colorLabel} ml-[6px] font-medium`}>
          {badgeMapping?.[value]?.label}
        </span>
      </div>
    );
  };

  const builderCell = (type: string, accessor: string, value, icon: string): JSX.Element => {
    if (type === 'badge') return buildBadges(value);
    if (type === 'icon')
      return (
        <div className="flex  items-center">
          <img src={icon} alt="icon" className="w-[25px] mr-2" />
          <span>{value}</span>
        </div>
      );
    if (type === 'button') {
      const isNew = !value?.credential?.createdAt;
      return (
        <ButtonNew
          onClick={() => {
            setOpen(true);
            setData(value);
          }}
          isSubmit={false}
          className={`w-[100px] ${
            isNew || value?.credential?.status === 'EXPIRED'
              ? 'bg-primary hover:bg-primaryDark text-white'
              : 'bg-white text-primaryDark border-solid border-gray-175  hover:bg-gray-50'
          }`}>
          {isNew ? t('Credentials.Activate') : t('Credentials.Update')}
        </ButtonNew>
      );
    }
    return <div key={accessor}>{value}</div>;
  };

  const getValueCell = (row, key: string, type: string, accessor: string): string => {
    let cellValue = row?.original?.[key];
    if (accessor.includes('credential')) cellValue = row?.original?.credential?.[key];
    if (type === 'date') {
      if (!cellValue) return '-';
      return moment(cellValue).format('ll');
    }
    if (type === 'button') return row?.original;
    return cellValue;
  };

  const getColumns = (): Column[] => {
    const columns = [
      { label: t('Credentials.Name'), accessor: 'name', key: 'name', type: 'icon', width: 'w-[30%]' },
      {
        label: t('Credentials.Added'),
        accessor: 'credential-created',
        key: 'createdAt',
        type: 'date',
        width: 'w-[20%]',
      },
      {
        label: t('Credentials.LastUpdated'),
        accessor: 'credential.update',
        key: 'credentialUpdatedAt',
        type: 'date',
        width: 'w-[20%]',
      },
      {
        label: t('Credentials.Status'),
        accessor: 'credential.status',
        key: 'status',
        type: 'badge',
        width: 'w-[20%]',
      },
      {
        label: '',
        type: 'button',
        accessor: 'action',
        key: 'status',
        width: 'w-[10%]',
      },
    ];

    const headers = columns.map((column) => {
      return {
        Header: column.label,
        accessor: column.accessor,
        Cell: ({ row }) => {
          const cellValue = getValueCell(row, column.key, column.type, column.accessor);
          return builderCell(column.type, column.accessor, cellValue, row?.original?.icon);
        },
        width: column.width,
      };
    });
    return headers;
  };

  return (
    <div className="flex mt-[10px]">
      <Table columns={getColumns()} data={credentials} pageSize={9} pagination />
      <CredentialsModal
        isOpen={open}
        onClose={() => setOpen(false)}
        credential={data}
        setloading={setloading}
        credentialUpdated={credentialUpdated}
      />
    </div>
  );
};

export default CredentialsList;
