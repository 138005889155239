import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ErrorMessage, Loading, NotFound } from '@Components';
import { useFetchData } from '@CustomHooks';
import { Claim, Permission } from '@Models';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import ManualUpload from './manualUpload';

interface ManualUploadProps {
  hasPermission: boolean;
  manualUpload?: string[];
}

const ManualUploadContainer = ({ hasPermission, manualUpload }: ManualUploadProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch, isFetching } = useFetchData(bopsApi.getManualUpload, 'luminate');

  if (isLoading) return <Loading title={t('ManualUpload.Loading')} />;

  if (!hasPermission || !data) return <NotFound />;

  if (error) {
    return <ErrorMessage internalMessage={`Unable to get configuration links: ${error}`} />;
  }

  return <ManualUpload clients={data} refetch={refetch} isFetching={isFetching} />;
};

const mapStateToProps = (state: RootState) => {
  const { authorization } = state;
  const { permissions, claims } = authorization;
  const manualUpload = claims && claims[Claim.ManualUpload];
  const hasPermission = permissions.includes(Permission.ManualUpload);

  return { hasPermission, manualUpload };
};

export default connect(mapStateToProps)(ManualUploadContainer);
