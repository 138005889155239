import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ManualUploadList, PageWrapper, Select } from '@Components';
import { SelectOptions } from '../../Components/Select/select';

interface Clients {
  name: string;
  date: string;
}

interface ManualUploadProps {
  clients: Clients[];
  refetch: (name: string) => void;
  isFetching: boolean;
}

const ManualUpload = ({ clients, refetch, isFetching }: ManualUploadProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [retailer, setRetailer] = useState('luminate');
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    refetch(retailer);
  }, [retailer]);

  const handleRetail = (e: SelectOptions): void => {
    setRetailer(e?.value || '');
  };

  useEffect(() => {
    if (isFetching) setLoading(true);
    if (!isFetching) setLoading(false);
  }, [isFetching]);

  return (
    <div className={`${loading ? 'pointer-events-none animate-pulse ' : ' '} bg-white w-full  h-full`}>
      <PageWrapper>
        <div className="flex">
          <div className="text-3xl font-semibold mb-6  text-black-100">{t('ManualUpload.Title')}</div>
        </div>
        <div className="border border-gray-100 border-solid rounded-xl">
          <div className="flex md:flex-row mx-6 my-4 items-center flex-col">
            <span className="font-bold text-xl justify-start text-black-100">
              {`${t('ManualUpload.All')} (${clients?.length ?? 0})`}
            </span>
            <div className="ml-auto flex gap-2 w-[50%] lg:flex-row flex-col">
              <div className="w-full text-[15px]">
                <Select
                  placeholder={t('User.Select')}
                  defaultValue={{ value: 'luminate', label: 'Luminate' }}
                  options={[
                    { value: 'kroger', label: 'Kroger' },
                    { value: 'luminate', label: 'Luminate' },
                  ]}
                  onChange={handleRetail}
                />
              </div>
            </div>
          </div>
          <ManualUploadList configures={clients ?? []} setLoading={setLoading} retailer={retailer} refetch={refetch} />
        </div>
      </PageWrapper>
    </div>
  );
};

export default ManualUpload;
