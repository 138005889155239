import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { DateTime } from 'luxon';

import {
  AiRecommendation,
  aiRecommendationFromJson,
  ClientConfiguration,
  ConfigurationFile,
  configurationFileFromJson,
  Options,
  PostSupplier,
  ProviderCredential,
  UserPagination,
  UserProvider,
} from '@Models';
import { getAccessToken } from '@Store';
import config from '../config';
import { snakeCaseKeys } from './helpers';

const BASEAPI_ADDRESS = config.REACT_APP_BASE_API_ADDRESS;

const bopsApi = ((baseApiAddress: string) => {
  const api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 50000,
  });
  // MIDDLEWARE
  // onRequest
  api.interceptors.request.use(
    async (configuration) => {
      const accessToken = await getAccessToken();

      const updatedConfig = configuration;
      updatedConfig.headers.Authorization = `Bearer ${accessToken}`;

      return updatedConfig;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  /*
  //onResponse
  instance.interceptors.response.use(
    response => {
      // do custom thing for every response
      return response
    },
    error => {
      return Promise.reject(error)
    }
  )
  */

  return {
    requestDownloadConfigurationFile: (fileType: string) => {
      return api.get(`configuration/file/presigned/${fileType}`);
    },
    requestUploadConfigurationFile: (fileType: string, contentType: string, extension: string) => {
      return api.post(`configuration/file/presigned/${fileType}`, {
        file_extension: extension,
        content_type: contentType,
      });
    },
    getFilesConfiguration: async (): Promise<ConfigurationFile[]> => {
      const response = await api.get('configuration/files');
      const { data } = response;
      return data.map((configurationFile) => configurationFileFromJson(configurationFile));
    },
    getPipelineLastUpdate: async (): Promise<string> => {
      const response = await api.get('pipelineruns/lastUpdate');
      const { data } = response;

      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const datetime = DateTime.fromISO(data).setZone(timeZone);

      return datetime.toLocaleString(DateTime.DATETIME_FULL);
    },
    getQuickSightDashboard: async (name = ''): Promise<string> => {
      const url = `quicksight/dashboard_embedded${name ? `?name=${name}` : ''}`;

      const response = await api.get(url);
      const { data } = response;

      return String(data);
    },
    getQuickSightTopic: async (): Promise<string> => {
      const response = await api.get(`quicksight/qsearchbar_embedded`);
      const { data } = response;

      return String(data);
    },
    getAiRecommendationByProductCode: async (productCode: string): Promise<AiRecommendation> => {
      const response = await api.get(`recommendations/ai?product_codes=${productCode}`);
      const { data } = response;
      let singleRec;

      if (Array.isArray(data)) singleRec = data[0]; // eslint-disable-line prefer-destructuring

      return aiRecommendationFromJson(singleRec || data);
    },
    getDocumentationPage: async (pageId: string): Promise<any> => {
      const response = await api.get(`resources/${pageId}`);
      const { data } = response;
      return data;
    },
    getClientConfiguration: async (): Promise<ClientConfiguration> => {
      const response = await api.get('configuration');
      const { data } = response;
      return data;
    },
    getCredentials: async (configuration: string[]): Promise<ProviderCredential[]> => {
      const response = await api.get(`credentials?conectors=${String(configuration)}`);
      return response.data.map((credential: any) => camelcaseKeys(credential));
    },
    postUpdateCredentials: async (credential: ProviderCredential): Promise<void> => {
      await api.post('credentials', snakeCaseKeys(credential));
    },
    getMFACode: async (phoneNumber: string): Promise<string> => {
      const response = await api.get(`mfa/code?phone_number=${phoneNumber}`);
      const { data } = response;
      return data;
    },
    buildUploadLink: async (
      clientName: string,
      fileName: string,
      contentType: string,
      retailer: string,
    ): Promise<string> => {
      const response = await api.post('manualUpload', {
        client_name: clientName,
        filename: fileName,
        content_type: contentType,
        retailer,
      });
      const { data } = response;
      return data;
    },
    reportFileUploaded: async (
      clientName: string,
      fileName: string,
      contentType: string,
      retailer: string,
    ): Promise<void> => {
      await api.post('manualUpload/uploaded', {
        client_name: clientName,
        filename: fileName,
        content_type: contentType,
        retailer,
      });
    },
    getUsers: async (
      filters: string,
      currentPage: number,
      pageSize: number,
      field: string | null,
      order: boolean | null,
    ): Promise<UserPagination> => {
      let query = `users?${filters}&page=${currentPage}&limit=${pageSize}`;
      if (field) {
        query += `&sort=${field}:${order ? '-1' : '1'}`;
      }
      const response = await api.get(query);
      return response.data;
    },
    postUser: async (userData: UserProvider): Promise<any> => {
      await api.post('users', userData);
    },
    blockUnblockUser: async (email: string, blocked: boolean): Promise<any> => {
      await api.patch(`users/email/${email}`, { blocked });
    },
    getClients: async (): Promise<Options[]> => {
      const response = await api.get('clients');
      return response.data;
    },
    getSuppliers: async (): Promise<[]> => {
      const response = await api.get('suppliers');
      return response.data;
    },
    postSupplier: async (provider: PostSupplier): Promise<any> => {
      await api.post('suppliers', provider);
    },
    getManualUpload: async (retail: string): Promise<[]> => {
      const response = await api.get(`manualUpload/${retail}`);
      return response.data;
    },
  };
})(BASEAPI_ADDRESS);

export default bopsApi;
